import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
    path: "/",
    name: 'login',
    component: () =>
        import('@/views/login/index.vue')
},
{
    path: '/layout',
    name: 'layout',
    component: () =>
        import('@/layout/adminMain.vue'),
    children: [{//首页
        path:'home',
        name:'homeIndex',
        component:()=>import('@/views/home/index.vue')
    },{//分类管理
        path: 'typeManager',
        name: 'typeIndex',
        component: () =>
            import('@/views/typeMangement')
    }, {//分类添加
        path: 'typeAdd',
        name: 'typeAdd',
        component: () => import('@/views/typeMangement/typeAdd.vue')
    }, {//菜谱管理
        path: 'menuManager',
        name: 'menuIndex',
        component: () => import('@/views/menuMangement')
    }, {//菜谱添加编辑
        path: 'menuAdd',
        name: 'menuAdd',
        meta: {
            keepAlive: true,
        },
        component: () => import('@/views/menuMangement/menuAdd.vue')
    }, {//菜谱步骤添加编辑
        path: 'menuSteep',
        name: 'menuSteep',
        component: () => import('@/views/menuMangement/menuSteep.vue')
    }, {//菜谱上传云端菜谱
        path: 'cloudUpload',
        name: 'cloudUpload',
        component: () => import('@/views/menuMangement/cloudUpload.vue')
    }, {//菜谱/云端详情
        path: 'menuInfo',
        name: 'menuInfo',
        component: () => import('@/views/menuMangement/menuInfo.vue')
    }, {//云端菜谱
        path: 'cloudManager',
        name: 'cloudIndex',
        component: () => import('@/views/cloudMangement')
    }, {//云端下载
        path: 'cloudDown',
        name: 'cloudDown',
        component: () => import('@/views/cloudMangement/cloudDown.vue')
    }, {//我的设备
        path: 'myDevice',
        name: 'deviceIndex',
        component: () => import('@/views/myDevice')
    }, {//我的设备-系统参数查看
        path: 'deviceInfo',
        name: 'deviceInfo',
        component: () => import('@/views/myDevice/deviceInfo.vue')
    }, {//我的设备-故障信息
        path: 'ErrorInfo',
        name: 'ErrorInfo',
        component: () => import('@/views/myDevice/ErrorInfo.vue')
    }, {//系统设置
        path: 'systemManger',
        name: 'systemIndex',
        component: () => import('@/views/systemMangement')
    }, {//设置密码
        path: 'setPassword',
        name: 'setPassword',
        component: () => import('@/views/systemMangement/setPassword.vue')
    }, {//系统设置-修改信息
        path: 'modifyInfo',
        name: 'modifyInfo',
        component: () => import('@/views/systemMangement/modifyInfo.vue')
    }, {//故障申报
        path: 'faultManger',
        name: 'faultIndex',
        component: () => import('@/views/faultMangement')
    }, {//故障申报-新增修改
        path: 'faultAdd',
        name: 'faultAdd',
        component: () => import('@/views/faultMangement/faultAdd.vue')
    }, {//故障申报-申报详情
        path: 'faultInfo',
        name: 'faultInfo',
        component: () => import('@/views/faultMangement/faultInfo.vue')
    }
	]
},

]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})
router.beforeEach((to, form, next) => {
	
    if (!$cookies.get('token')) {
        if (to.path !== '/') router.push('/')
        next()
        return
    }
    next()
})
export default router
