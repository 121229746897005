import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    keepList:[],//是否缓存组件
    headerList: [],
    steepList:[],//步骤数据
  },
  getters: {
  },
  mutations: {
    //头部标签数据整理
    tageChange(state, obj) {
      if (!state.headerList.some(item => { return item.name === obj.name })) {
        state.headerList = [obj]
      }
    },
     //数据整理-菜谱步骤
     arrangement(state){
      state.steepList.forEach((item,index) =>{
        item.steep_index = index+1
      })
    },
    //步骤数据替换
    steepRplace(state,{obj,index}){
      state.steepList.splice(index,1,obj)
    },
    //删除步骤
    steepDelete(state,index){
      state.steepList.splice(index,1)
    },
	// 删除缓存组件
	deteleKeepList(state, val) {
		let index = state.keepList.indexOf(val);
		if(index > -1) {
			state.keepList.splice(index, 1)
		}
	},
	// 向keepList添加缓存组件
	modifyKeepList(state, val) {
		state.keepList.push(val);
	},
  },
  actions: {
  },
  modules: {
  }
})
