<template>
    <div class="mainTitle">
        <div class="line"></div>
        <span>{{title}}</span>
        <div class="titleTips" v-if="tipsShow">
            <el-image src="/image/654@2x.png"></el-image>
            <span>{{tips || '设备01'}}</span>
        </div>
    </div>
</template>

<script>
export default{
    props:['title','tipsShow','tips'],
    data(){
        return{
            show:this.tipsShow || false
        }
    },
}
</script>

<style lang="scss" scoped>
.mainTitle {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .line {
        width: 5px;
        height: 18px;
        background: linear-gradient(180deg, #5E81F4 0%, #23D7C1 100%);
        margin-right: 6px;
    }
    span{
        font-size: 18px;
        font-weight: 500;
        color: #021140FF;
    }
    .titleTips{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3px 10px;
        border-radius: 10px;
        background: #5E81F4FF;
        margin-left: 10px;
        .el-image{
            width: 12px;
            height: 11px;
        }
        span{
            font-size: 12px;
            font-weight: 400;
            color: white;
            margin-left: 1px;
        }
    }
}
</style>